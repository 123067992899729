<template>
  <div class="layout layout_default">
    <main><slot /></main>

    <atoms-simple-footer />
    <modules-urgent-banner />
  </div>
</template>

<script lang="ts" setup></script>
