<template>
  <footer>
    <span>ⓒ NEXON Korea Corporation All Rights Reserved.</span>
  </footer>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
footer {
  display: flex;
  // width: 1920px;
  padding: 24px 0px 18px 0px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #120937;
  color: $white;
  font-size: 14px;
  font-weight: 500;

  > a {
    opacity: 0.7;
  }
  > hr {
    display: inline-block;
    width: 1px;
    height: 10px;
    background-color: $white;
    opacity: 0.3;
    margin: 0;
    border: 0;
  }
  > span {
    opacity: 0.7;
  }
}
</style>
